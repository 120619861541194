@import '../../styles/customMediaQueries.css';

.root {
  font-size: 14px;
  line-height: 24px;
  font-weight: var(--fontWeightRegular);
  color: var(--failColor);

  margin-top: 4px;
  margin-bottom: 0;
  overflow: hidden;

  @media (--viewportMedium) {
    margin-top: 6px;
    margin-bottom: 10px;
  }
}
