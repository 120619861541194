.root {
}

.input {
  composes: checkBoxBorders from global;
  height: 48px;
  border-radius: 4px;
  padding-left: 8px;
  /* border-bottom-color: var(--attentionColor); */
}

.inputSuccess {
  /* border-bottom-color: var(--successColor); */
}

.inputError {
  /* border-bottom-color: var(--failColor); */
}

.textarea {
}

.mandatoryComponent {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  color: #d03f41;
}
