@import '../../../styles/customMediaQueries.css';

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
}

.avatar {
  /* Layout */
  flex-shrink: 0;
  margin: 0;
}

.greeting {
  /* Font */
  composes: h1 from global;
  margin-bottom: 1px;
  margin-top: 16px;
}

.logoutButton {
  /* Position and dimensions */
  display: inline;

  /* Borders */
  border: none;

  /* Text size should be inherited */
  text-decoration: none;

  /* Logout font is smaller and gray since the action is not recommended. */
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  line-height: 24px;

  color: var(--matterColorAnti);

  /* Position component */
  width: initial;
  margin: 4px 0 24px 0;
  padding: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.inbox {
  /* Font */
  composes: h2 from global;
  color: var(--marketplaceColor);
  position: relative;

  margin-top: auto;
  margin-bottom: 13px;
}

.navigationLink {
  /* Font */
  composes: h2 from global;
  color: var(--marketplaceColor);

  /* Layout */
  margin-top: 0;
  margin-bottom: 11px;
}
.currentPageLink {
  color: var(--marketplaceColorDark);
}

.notificationBadge {
  position: absolute;
  top: 4px;
  right: -26px;

  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.menuWrapper {
  padding: 0 16px;
  width: 100%;
}

.menuWrapper > div > a > span,
.menuWrapper > div > span {
  color: var(--matterColorDark);
}

.createNewListingLink {
  composes: button buttonFont buttonText marketplaceButtonBorders buttonColors from global;
}

.authenticationGreeting {
  /* Font */
  composes: h1 from global;

  margin-bottom: 24px;
}
.authenticationLinks {
  white-space: nowrap;
}

.signupLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}

.loginLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  height: 100%;

  text-decoration: none;
  white-space: nowrap;
  color: var(--matterColorDark);
}

.loginLink:hover {
  text-decoration: none;
}

.menuCellIcon {
  height: 24px;
  width: 24px;
}

.currentPage {
  color: var(--matterColorDark);

  /* black left border */
  border-left: 5px solid black;
  margin-left: -24px;
  padding-left: 19px;
}

.menuCell {
  height: 64px;
  border-bottom: 1px solid #e9ecef;
  width: 100%;
  position: relative;
}

.menuCell:hover,
.menuCellLogOut:hover {
  cursor: pointer;
}

.actionButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
}

.actionSecondaryButton {
  composes: button buttonFont buttonText buttonBordersSecondary buttonColorsSecondary from global;
}

.userInfo {
  margin-left: 16px;
}

.userCellWrapper {
  display: flex;
  flex-direction: row;
  margin: 0 16px 0 16px;
  height: 84px;
  position: relative;
  align-items: center;
}

.arrowImage {
  position: absolute;
  right: 0;
  height: 24px;
  width: 24px;
}

.arrowImageLogOut {
  position: absolute;
  right: 16px;
  height: 24px;
  width: 24px;
}

.displayName {
  margin: 0;
}

.displayName:hover {
  cursor: pointer;
}

.menuCellTitle {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: #212529;
}

.spacer {
  height: 24px;
}

.loginButtonWrapper {
  padding: 16px;
  width: 100%;
}

.countrySwitch {
  width: 100%;
  padding: 14px 16px;
}

.localeOption {
  display: flex;
  align-items: center;
}

.countryLogo {
  width: 24px;
  height: 24px;
}

.countryName {
  margin-left: 4px;
}

.userProfileWrapper {
  width: 48px;
  height: 48px;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #edf1f0;
  color: var(--matterColorLight);

  &:hover {
    text-decoration: none;
  }
}

.userName {
  font-size: 16px;
  line-height: 32px;
  margin: 0;
  color: var(--marketplaceColor);
}

.inboxIconWrapper {
  position: relative;
}

.notificationDot {
  margin-left: 8px;
  margin-top: 2px;

  width: 18px;
  height: 18px;
  border-radius: 50%;

  background-color: #d03f41;

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);

  display: flex;
  justify-content: center;
  align-items: center;
}

.notificationDot > span {
  font-size: 12px;
  font-weight: 500;
  color: white;
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.menuCellTitleWrapper {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.menuCellLogOut {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.menuCellLogOutTitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: #6c757d;
}
