.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
}

.description {
  font-weight: var(--fontWeightRegular);
  line-height: 1.5rem;
  color: var(--matterColor);
}

.title {
  margin-bottom: 4px;
}

.toggleButtonInput {
  height: 0;
  width: 0;
  visibility: hidden;
}

.toggleButtonLabel {
  cursor: pointer;
  text-indent: -9999px;
  width: 44px;
  height: 24px;
  background: #dee2e6;
  display: block;
  border-radius: 40px;
  position: relative;
}

.toggleButtonLabel:after {
  content: '';
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  background-color: var(--matterColor);
  border-radius: 40px;
  transition: 0.3s;
  border-style: solid;
  border-color: #e9ecef;
}

.toggleButtonInput:checked + label {
  background: var(--marketplaceColor);
}

.toggleButtonInput:checked + label:after {
  left: calc(100% - 1px);
  transform: translateX(-100%);
  background: #fff;
  border: none;
}

.toggleButtonLabel:active:after {
  width: 30px;
}
