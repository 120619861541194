.input {
  composes: checkBoxBorders from global;
  height: 48px;
  padding-left: 8px;
}

.inputError {
  border-color: var(--failColor);
}

.mandatoryComponent {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  color: #d03f41;
}
