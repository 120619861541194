@import '../../styles/customMediaQueries.css';

.root {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);

  width: 100%;
  padding: 24px 24px 42px 24px;
  background-color: var(--matterColorBright);
  position: relative;
  margin-top: 64px;

  @media (--viewportMedium) {
    padding: 0 24px 33px 24px;
  }

  @media (--viewportLarge) {
    padding: 0 24px 64px 24px;
    border-top: none;
  }
}

/* Wrapper to limit the top border in desktop
 * view from the outermost paddings*/
.topBorderWrapper {
  @media (--viewportMedium) {
    padding-top: 42px;
  }

  @media (--viewportLarge) {
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: var(--matterColorNegative);
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportMedium) {
    margin: 0 auto;
  }

  @media (--viewportLarge) {
    max-width: 1320px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.logo {
  object-fit: contain;
  object-position: left center;
  width: 100%;
  height: 32px;
}

.organizationWrapper {
  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
  }
}

.socialIcon {
  height: 24px;
  width: 24px;
}

.socialMediaLinks {
  display: flex;
}

.contactDetails {
  display: block;
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 18px;
  letter-spacing: 0em;
  color: var(--matterColorDark);
}

.actionsWrapper {
  @media (--viewportMedium) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 32px;
  }
}

.actionLinkTitle {
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 18px;
  letter-spacing: 0em;
  color: var(--matterColorDark);
}

.actionLinkTitle:hover {
  cursor: pointer;
}

.divider {
  border-top: solid 1px #dee2e6;
}

.contentRights {
  padding: 32px 0 0 0;
  text-align: center;

  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  line-height: 18px;
  letter-spacing: 0em;
  color: #495057;

  @media (--viewportMedium) {
    text-align: start;
  }
}
