@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  background: white;
  border-radius: 4px;
  box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12);
  cursor: pointer;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    box-shadow: 0px 4px 6px 0px rgba(24, 39, 75, 0.06);
    border: 1px solid rgba(222, 226, 230, 1);
  }
}

.listingImage {
  width: 78px;
  height: 78px;
  object-fit: cover;
  border-radius: 4px;
}

.itemTitle {
  display: block;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  margin: 0;
  line-height: 22px;
  color: var(--matterColorDark);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chevronWrapper {
  position: absolute;
  right: 8px;

  display: flex;
}

.chevronDescription {
  display: none;

  @media (--viewportMedium) {
    display: unset;

    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    color: #212529;

    margin-right: 8px;
  }
}

.chevron {
  width: 24px;
  height: 24px;
  /* position: absolute;
  right: 8px; */
}

.actionButtons {
  display: flex;
  flex-direction: row;
}

.editListingButton {
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--matterColorDark);
  font-weight: var(--fontWeightRegular);
  font-size: 14px;
  line-height: 18px;

  background: #ffffff;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  width: 84px;
  height: 26px;

  text-decoration: none;
}

.deleteListingModal {
  min-width: 310px;
}

.deleteListingButton {
  display: flex;
  align-items: center;
  justify-content: center;

  color: #d03f41;
  font-weight: var(--fontWeightRegular);
  font-size: 14px;
  line-height: 18px;

  background: #ffffff;
  border: 1px solid #d03f41;
  border-radius: 4px;
  width: 84px;
  height: 26px;

  text-decoration: none;
}

.deleteListingButton:hover {
  cursor: pointer;
}

.deleteListingModalTitle {
  color: var(--matterColorDark);
  font-weight: var(--fontWeightMedium);
  margin: 0 0 24px 0;
  width: 100%;
  text-align: center;

  @media (--viewportMedium) {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
  }
}

.deleteButton {
  composes: button buttonFont buttonText buttonBorders buttonColorsDanger from global;
  margin-top: 16px;

  @media (--viewportMedium) {
    min-height: 44px !important;
  }
}

.closeButton {
  composes: button buttonFont buttonText buttonSecondaryAccentBorder buttonSecondaryAccentColors from global;
  margin-top: 16px;

  @media (--viewportMedium) {
    min-height: 44px !important;
  }
}

.closeButton:hover {
  background-color: var(--matterColorWhite);
}

.checkboxes label {
  @media (--viewportMedium) {
    margin-bottom: 0px;
  }
}

.checkboxes legend {
  margin-bottom: 15px;
  font-size: 16px;
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--matterColorNegative); /* Loading BG color */
  border-radius: 4px;

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border-radius: 4px;
  width: 100%;
  height: 100%;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  min-width: 0;
  padding-right: 16px;
  height: 78px;
}

.importedInfo {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  flex: 1;

  min-width: 0;
}

.inProgresBadge {
  background-color: #F4EEE7;
  border: 1px solid #EBCDAB;
  color: #000;
  padding: 4px 8px;
  line-height: 1;
  border-radius: 9999px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
}

.declinedBadge {
    background-color: #FEF6F7;
  border: 1px solid #CE6865;
  color: #000;
  padding: 4px 8px;
  line-height: 1;
  border-radius: 9999px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-right: 18px;
}

.priceValue {
  color: #495057;
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  line-height: 22px;

  margin-top: 0;
  margin-bottom: 0;
}

.perUnit {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);
  display: inline-flex;
  align-items: center;
  margin-left: 8px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  color: #495057;
  font-size: 16px;
  font-weight: var(--fontWeightMedium);
  line-height: 22px;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
