.root {
  height: 56px;
  width: 100%;

  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;

  filter: drop-shadow(0px 4px 6px rgba(24, 39, 75, 0.06));
  background: white;
}

.title {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  width: 50vw;

  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  color: #212529;

  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.closeBtn {
  width: 24px;
  height: 24px;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 24px;
    height: 24px;
  }
}
