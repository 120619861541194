@import '../../styles/customMediaQueries.css';

.root {
  position: fixed;
  bottom: 0;
  z-index: 1000;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 16px 0;

  background-color: var(--matterColorDark);
  color: #fff;
}

.cookieContent {
  max-width: 1440px;
  width: 100%;
  padding: 0 16px;

  display: flex;
  flex-direction: column;

  align-items: flex-end;
  justify-content: space-between;

  @media (--viewportMedium) {
    flex-direction: row;

    padding: 0 60px;
  }
}

.cookieMessageWrapper {
  max-width: 760px;
}

.cookieConsentTitle {
  font-size: 24px;
  font-weight: var(--fontWeightMedium);
  line-height: 32px;
  letter-spacing: 0em;
  color: white;

  margin: 0 0 8px 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.cookieConsentMessage {
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 22px;
  letter-spacing: 0em;
  color: white;
}

.continueBtn {
  width: 100%;
  height: 44px;
  margin-top: 24px;

  font-size: 16px;
  font-weight: var(--fontWeightMedium);
  line-height: 22px;
  letter-spacing: 0em;
  color: var(--matterColorDark);

  background-color: white;
  border: solid 1px var(--matterColorNegative);
  border-radius: 4px;
  cursor: pointer;

  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    height: 36px;
    max-width: 400px;
    min-width: 120px;
    margin-left: 24px;
    margin-top: 0;
    flex: 1;
  }

  &:hover {
    background-color: var(--matterColorNegative);
  }
}
