@import '../../styles/customMediaQueries.css';

.root {
  width: 100%;
  position: relative;
  margin-top: 40px;

  @media (--viewportMedium) {
    margin-top: 72px;
  }
}
