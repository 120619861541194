.root {
  text-align: left;
  position: relative;
  border-radius: 5px;
  width: 54px;
  height: 44px;
  margin-top: 18px;
}

.input {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;

  font-weight: var(--fontWeightRegular);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0em;
  color: #6c757d;
}

.input:hover {
  cursor: pointer;
}

.optionMenu {
  position: absolute;
  transform: translateY(4px);
  width: 100%;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  overflow: auto;
  max-height: 150px;
  background-color: #fff;
  z-index: 1;
}

.optionItem {
  padding: 5px;
  cursor: pointer;

  font-weight: var(--fontWeightRegular);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0em;
  color: #6c757d;
}

.dropDownTooltip {
  display: flex;
}
