@import '../../styles/customMediaQueries.css';

.sectionContainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  padding: 16px;
  border-radius: 8px;
  gap: 8px;
  background: var(--matterColorGrey);
}

.sectionContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  text-align: left;
}

.sectionContent > h3 {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  margin: 0;
}

.sectionContent > p {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  margin: 0;
}

.sectionIcon {
  font-size: 2rem;
  color: var(--matterColorPrimary);
}
