@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.container {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkMark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid var(--borderColor);
  border-radius: 4px;
  cursor: pointer;
  /* background-color: #eee; */
}

.container input:checked ~ .checkMark {
  background-color: var(--marketplaceColor);
  border-color: var(--marketplaceColor);
}

.checkMark:after {
  content: '';
  position: absolute;
  display: none;
}

.container input:checked ~ .checkMark:after {
  display: block;
}

.container .checkMark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.label {
  display: flex;
  align-items: center;
  padding: 0;
  margin-bottom: 0px;
}

.textRoot {
  composes: marketplaceListingAttributeFontStyles from global;
  color: var(--matterColorDark);
  cursor: pointer;
}
