@import '../../styles/customMediaQueries.css';

.root {
  z-index: 100;
  height: 100dvh;
  width: 100vw;

  background-color: rgba(51, 51, 51, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.modalContent {
  z-index: 1000;
  height: fit-content;
  max-width: 100%;
  width: fit-content;
  padding: 24px 16px;
  background-color: white;
  opacity: 1;
  margin-left: 16px;
  margin-right: 16px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (--viewportMedium) {
    max-width: 343px;
  }
}
